import {
  Component, inject, OnInit, signal,

} from '@angular/core';
import {FilterChipComponent} from "../filter/filter-chip/filter-chip.component";
import {AppSvgIconComponent} from "../../../shared/components/app-svg-icon/app-svg-icon.component";
import {
  SingleSelectionFieldComponent
} from "../../../shared/inputs/single-selection-field/single-selection-field.component";
import {ReactiveFormsModule} from "@angular/forms";
import {FormsModule} from '@angular/forms';
import {AsyncPipe, CommonModule, JsonPipe, NgForOf, NgIf} from "@angular/common";
import {ButtonComponent} from "../../../shared/components/button/button.component";
import {CounterInputComponent} from "../../../shared/inputs/counter-input/counter-input.component";
import {BaseComponent} from "../../../shared/base/base-component";
import {DatePickerComponent} from "../../../shared/inputs/_base/date-picker/date-picker.component";
import {TextInputComponent} from "../../../shared/inputs/text-input/text-input.component";
import {
  SingleItemPickerInputComponent
} from "../../../shared/inputs/single-item-picker-input/single-item-picker-input.component";
import {DateSelectorInputComponent} from "../../../shared/inputs/date-selector-input/date-selector-input.component";
import {TopNavComponent} from "../../../features/layout/top-nav/top-nav.component";

import {RatingInputComponent} from "../../../shared/inputs/rating-input/rating-input.component";
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {FooterComponent} from "../../../features/layout/footer/footer.component";
import {CheckboxComponent} from "../../../shared/inputs/checkbox/checkbox.component";
import {RadioButtonComponent} from "../../../shared/inputs/radio-button/radio-button.component";
import {
  PickupCheckoutComponent
} from "../../../features/pickup-order/presentation/pages/pickup-checkout/pickup-checkout.component";
import {MatFormField, MatFormFieldModule} from "@angular/material/form-field";
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteTrigger,
  MatOption
} from "@angular/material/autocomplete";
import {MatInput, MatInputModule} from "@angular/material/input";
import {RestaurantSuggestionComponent} from "../../../features/restaurant-suggestion/restaurant-suggestion.component";
import {
  CheckInConfirmationOverlayComponent
} from "../../../features/pickup-order/presentation/overlay/check-in-confirmation-overlay/check-in-confirmation-overlay.component";
import {MatDialog} from "@angular/material/dialog";
import {DateRangePickerComponent} from "../../../shared/inputs/date-range-picker/date-range-picker.component";
import {DatePickerInputComponent} from "../../../shared/inputs/date-picker-input/date-picker-input.component";

@Component({
  selector: 'app-test',
  standalone: true,
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss',
  imports: [
    ReactiveFormsModule,
    InfiniteScrollModule,
    FormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatInputModule,
    MatFormFieldModule
  ]
})
export class TestComponent extends BaseComponent {



}
