import {Component, OnInit, inject, computed} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {RestaurantService} from "../restaurants/data/restaurant.service";
import {debounceTime, distinctUntilChanged} from "rxjs";
import {State} from "../../shared/base/base-state";
import {
  RestaurantSuggestionResponse,
  RestaurantSuggestionResponseData
} from "../restaurants/data/restaurant-suggestion/restaurant-suggestion-response";
import {BaseComponent} from "../../shared/base/base-component";
import {filter} from "rxjs/operators";
import {CommonModule} from '@angular/common';
import {RestaurantSuggestionRequest} from "../restaurants/data/restaurant-suggestion/restaurant-suggestion-request";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatAutocomplete, MatAutocompleteTrigger, MatOption} from "@angular/material/autocomplete";
import {MatInput} from "@angular/material/input";
import {AppSvgIconComponent} from "../../shared/components/app-svg-icon/app-svg-icon.component";
import {NetworkImageComponent} from "../../shared/components/network-image/network-image.component";

@Component({
  selector: 'app-restaurant-suggestion',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    CommonModule,
    MatFormField,
    MatAutocomplete,
    MatOption,
    MatLabel,
    MatAutocompleteTrigger,
    MatInput,
    AppSvgIconComponent,
    NetworkImageComponent
  ],
  templateUrl: './restaurant-suggestion.component.html',
  styleUrl: './restaurant-suggestion.component.scss'
})
export class RestaurantSuggestionComponent extends BaseComponent implements OnInit {
  searchControl = new FormControl('');
  restaurantService = inject(RestaurantService);

  suggestionState = new State<RestaurantSuggestionResponse>();

  ngOnInit() {
    this.searchControl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((term): term is string => typeof term === 'string' && term.trim().length > 0)
    ).subscribe(term => {
      this.suggestRestaurants(term);
    });
  }

  suggestRestaurants(autoCompleteText: string) {
    const request: RestaurantSuggestionRequest = {
      autocompletetext: autoCompleteText,
      pagesize: 10
    };

    this.executeRequest({
      state: this.suggestionState,
      request: this.restaurantService.suggestRestaurants(request),
    });
  }

  filteredRestaurants = computed(() => {
    const response = this.suggestionState.response();
    const searchTerm = this.searchControl.value?.toLowerCase() || '';

    if (response?.data) {
      return response.data.filter(restaurant =>
        restaurant.restaurantName.toLowerCase().includes(searchTerm)
      );
    }
    return [];
  });

  displayFn(restaurant: RestaurantSuggestionResponseData | string): string {
    if (typeof restaurant === 'string') {
      return restaurant;
    }
    return restaurant && restaurant.restaurantName ? restaurant.restaurantName : '';
  }


  onRestaurantSelected(restaurant: RestaurantSuggestionResponseData) {
    if (restaurant.restaurantId) {
      this.router.navigate([`/dine-in-restaurant-details/${restaurant.restaurantId}`]);
    }
  }
}

